import React from "react";

export const Faq = (props) => {
  return (
    <div id="faq" className="text-center" style={{ paddingTop: "100px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="section-title">
              <h2>FAQ</h2>
              <p>{props.data ? props.data.paragraph : ""}</p>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {props.data && props.data.faqList
                  ? props.data.faqList.map((faq, index) => {
                      // Split the string into question and answer using the delimiter '|'
                      const [question, answer] = faq.split("|");
                      return (
                        <li key={`faq-${index}`} style={{ marginBottom: "20px" }}>
                          <strong>Q: {question}</strong>
                          <br />
                          <strong>A: </strong>{answer}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <img src="img/cut.png" className="img-responsive" alt="FAQ Image" />
          </div>
        </div>
      </div>
    </div>
  );
};