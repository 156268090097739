import React from 'react';

export const About = (props) => {
  const paragraphs = props.data ? props.data.paragraph.split('\n') : [];

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/about2.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              {paragraphs.length > 0 ? (
                paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))
              ) : (
                <p>loading...</p>
              )}
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12"></div>
                <div className="col-lg-6 col-sm-6 col-xs-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};